import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';

import { WINDOW } from '@core/helpers/global-objects';
import { LocalStorageService } from '@core/services/persistance.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { subnavAnimation } from '@design/layout/page-with-subnav/animations';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

const SUBNAV_STATE_LOCAL_STORAGE_KEY = 'subnavCollapsed';
const OVERLAY_SUBNAV_BREAKPOINT = 1076;

@UntilDestroy()
@Component({
  selector: 'cc-page-with-subnav',
  templateUrl: './page-with-subnav.component.html',
  styleUrls: ['./page-with-subnav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [subnavAnimation],
  imports: [NgTemplateOutlet, ButtonComponent, TooltipDirective, TranslateModule],
  encapsulation: ViewEncapsulation.None,
})
export class PageWithSubnavComponent implements OnInit {
  @Input({ required: true })
  id: string = '';

  @Input()
  subnavTemplate: TemplateRef<unknown>;

  @Input()
  previewTriggerElementId: string;

  protected subnavCollapsed: boolean = false;
  protected previewTriggered = false;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipPosition = TooltipPosition;

  private previousViewportWidth: number | undefined;

  private readonly storage = inject(LocalStorageService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly window = inject(WINDOW);

  get subnavStateLocalStorageKey(): string {
    return `${this.id}.${SUBNAV_STATE_LOCAL_STORAGE_KEY}`;
  }

  get subnavAnimationState(): 'expanded' | 'collapsed' {
    if (this.subnavCollapsed) return 'collapsed';
    return 'expanded';
  }

  get compactMode(): boolean {
    return this.window.innerWidth <= OVERLAY_SUBNAV_BREAKPOINT;
  }

  ngOnInit(): void {
    this.subnavCollapsed = this.compactMode ? true : this.storage.get(this.subnavStateLocalStorageKey) === true;
    this.initPreviewTriggerListener();
  }

  setSubnavState(collapsed: boolean): void {
    this.subnavCollapsed = collapsed;
    if (!this.compactMode) this.storage.set(this.subnavStateLocalStorageKey, collapsed);

    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    if (this.compactMode && this.previousViewportWidth > OVERLAY_SUBNAV_BREAKPOINT) this.subnavCollapsed = true;
    if (!this.compactMode) this.subnavCollapsed = this.storage.get(this.subnavStateLocalStorageKey) === true;

    this.cdr.detectChanges();
    this.previousViewportWidth = this.window.innerWidth;
  }

  private initPreviewTriggerListener(): void {
    if (!this.previewTriggerElementId) return;

    const previewTriggerElement = document.getElementById(this.previewTriggerElementId);
    if (!previewTriggerElement) return;

    const triggerMouseEnter$ = fromEvent(previewTriggerElement, 'mouseenter');
    const triggerMouseLeave$ = fromEvent(previewTriggerElement, 'mouseleave');

    const triggerHover$ = triggerMouseEnter$.pipe(
      untilDestroyed(this),
      switchMap(() => {
        return triggerMouseLeave$.pipe(
          map(() => false),
          startWith(true),
        );
      }),
      debounceTime(0),
    );

    triggerHover$
      .pipe(
        tap((isHovered) => {
          this.previewTriggered = isHovered;
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
