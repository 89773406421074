import { animate, state, style, transition, trigger } from '@angular/animations';

export const subnavAnimation = trigger('subnavAnimation', [
  state(
    'collapsed',
    style({
      width: '12px',
      opacity: 0,
    }),
  ),
  state('expanded', style({ width: '248px', opacity: 1 })),
  transition('collapsed <=> expanded', animate('0.2s ease-in-out')),
]);
