<div class="page">
  @if (subnavTemplate) {
    <div
      [@subnavAnimation]="subnavAnimationState"
      [class.page__subnav--collapsed]="subnavCollapsed"
      [class.page__subnav--default]="!subnavCollapsed"
      class="page__subnav"
    >
      <div class="page__subnav-content">
        <ng-container [ngTemplateOutlet]="subnavTemplate" />
      </div>
    </div>

    <div
      (click)="subnavCollapsed = true"
      [class.page__subnav-backdrop--visible]="!subnavCollapsed"
      class="page__subnav-backdrop"
    ></div>

    <div
      [class.page__subnav-preview--visible]="previewTriggered && subnavCollapsed"
      class="page__subnav-preview"
    >
      <ng-container [ngTemplateOutlet]="subnavTemplate" />
    </div>

    <div class="page__toggle">
      <div class="page__toggle-divider"></div>

      <app-button
        (click)="setSubnavState(!this.subnavCollapsed)"
        [ccTooltip]="
          !subnavCollapsed ? ('pageWithSubnav.hideSubnav' | translate) : ('pageWithSubnav.showSubnav' | translate)
        "
        [size]="ButtonSize.Big"
        [tooltipPosition]="TooltipPosition.Right"
        [type]="ButtonType.GhostInvert"
        class="page__toggle-button"
      >
        <i
          [class.icon-collapse-alt-2]="!subnavCollapsed"
          [class.icon-expand-alt-2]="subnavCollapsed"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  }

  <div class="page__content">
    <ng-content />
  </div>
</div>
